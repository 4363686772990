body {
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
  background: #9352b8;
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}
